<script setup lang="ts">
type Props = {
  icon?: string;
  iconClass?: string;
  isOpen?: boolean;
  title?: string;
  accordionIndex?: number | null;
  labelClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  icon: 'chevron-down',
  isOpen: false,
  title: '',
  accordionIndex: null,
  iconClass: 'text-icon',
  labelClass: ''
});

const emit = defineEmits(['update:isOpen']);
const id = useId();

const internalIsOpen = shallowRef<boolean>(props.isOpen);

watch(
  () => props.isOpen,
  (newIsOpen) => {
    internalIsOpen.value = newIsOpen;
  }
);

const handleToggle = () => {
  internalIsOpen.value = !internalIsOpen.value;
  if (props.accordionIndex !== null && props.accordionIndex !== undefined) {
    emit('update:isOpen', props.accordionIndex ?? 0);
  }
};
</script>

<template>
  <article>
    <button
      class="flex w-full flex-row items-center justify-between py-6"
      :class="labelClass"
      role="button"
      :aria-expanded="internalIsOpen"
      :aria-controls="id"
      @click="handleToggle"
    >
      <slot name="title">
        <h3
          v-if="title"
          class="hyphens-manual break-words text-left font-base font-bold"
        >
          {{ title }}
        </h3>
      </slot>

      <BaseIcon
        :name="icon"
        :class="['transition-all duration-300', { 'rotate-180': internalIsOpen }, iconClass]"
      />
    </button>

    <BaseTransitionExpand>
      <div
        v-show="internalIsOpen"
        :id="id"
        :aria-hidden="!internalIsOpen"
      >
        <slot></slot>
      </div>
    </BaseTransitionExpand>
  </article>
</template>
