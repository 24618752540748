<script setup lang="ts">
const activeAccordion = shallowRef<number | null>(null);

function setActiveAccordion(index: number) {
  if (index === activeAccordion.value) {
    activeAccordion.value = null;
    return;
  }

  activeAccordion.value = index;
}

function isActiveAccordion(index: number) {
  return index === activeAccordion.value;
}
</script>

<template>
  <div>
    <slot
      :active-accordion="activeAccordion"
      :set-active-accordion="setActiveAccordion"
      :is-active-accordion="isActiveAccordion"
    />
  </div>
</template>
